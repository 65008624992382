import React, { Component } from 'react';
import { Link } from 'gatsby';
import * as routes from '../constants/routes';

import Layout from '../components/layout';
import MapContainer from '../components/Maps/index';
import withAuthorization from '../components/Session/withAuthorization';
import AuthUserContext from '../components/Session/AuthUserContext';
import { AppWrapper } from '../components/styled';
import UserSmokeSessions from '../components/UserSmokeSessions';
import { fromObjectToList } from '../components/helpers';
import { AddSessionLinkWrapper } from '../components/styled';

class HomePageBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initFirebase: false,
      users: [],
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this.state.initFirebase) {
      this.props.firebase.onceGetUsers().then(snapshot =>
        this.setState(() => ({
          users: fromObjectToList(snapshot.val()),
        })),
      );
      this.setState({ initFirebase: true });
    }
  };

  componentDidUpdate() {
    this.firebaseInit();
  }

  render() {
    return (
      <React.Fragment>
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              <h1>Welcome {authUser.email}</h1>
              <UserSmokeSessions email={authUser.email} />
              <AddSessionLinkWrapper>
                <Link to={routes.ADD_SMOKE_SESSION}>
                  Add a smoke session
                </Link>
              </AddSessionLinkWrapper>
            </div>
          )}
        </AuthUserContext.Consumer>
      </React.Fragment>
    );
  }
}

const authCondition = authUser => !!authUser;

const HomePage = withAuthorization(authCondition)(HomePageBase);

export default () => (
  <Layout>
    <AppWrapper>
      <HomePage />
    </AppWrapper>
    <MapContainer />
  </Layout>
);
