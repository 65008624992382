import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { withFirebase } from '../Firebase/FirebaseContext';
import { fromObjectToList } from '../helpers';

const SmokeSessionsList = styled.div`
  border-bottom: 1px solid;
  margin-bottom: 10px;
`;

const FlexWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const ListHeader = styled.div`
  display: flex;
  border-bottom: 1px solid;
`;

const ListItem = styled.div`
  width: 33%;
  padding: 10px;
  line-height: 100%;
`;

class UserSmokeSessions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initFirebase: false,
      loaded: false,
      userSmokeSessions: [],
    };
  }
  firebaseInit = () => {
    if (this.props.firebase && !this.state.initFirebase) {
      this.props.firebase
        .onceGetSmokeSessionsByEmail(this.props.email)
        .then(snapshot =>
          this.setState(() => ({
            userSmokeSessions: fromObjectToList(snapshot.val()),
            loaded: true,
          })),
        );
      this.setState({ initFirebase: true });
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  render() {
    return (
      <SmokeSessionsList>
        {this.state.loaded && (
          <div>
            You have {this.state.userSmokeSessions.length} smoke
            sessions saved.
          </div>
        )}
        {this.state.loaded &&
          this.state.userSmokeSessions.length > 0 && (
            <ListHeader>
              <ListItem>Strain</ListItem>
              <ListItem>Location</ListItem>
              <ListItem>Date</ListItem>
            </ListHeader>
          )}
        {this.state.loaded &&
          this.state.userSmokeSessions.map(item => {
            return (
              <FlexWrapper key={item.index}>
                <ListItem>{item.strain}</ListItem>
                <ListItem>{item.location || 'n/a'}</ListItem>
                <ListItem>
                  {moment(item.createdAt).format('M/D/YYYY') || 'n/a'}
                </ListItem>
              </FlexWrapper>
            );
          })}
      </SmokeSessionsList>
    );
  }
}

export default withFirebase(UserSmokeSessions);
